.main-menu {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index:20;
  display: flex;
  z-index: -10;
  transition: 2s;
}

.main-menu__left {
  width: 50vw;
  height: 100%;
  background-color: rgba(24, 54, 105, 0.85);
  position: relative;
  left: -100%;
  transition: 1.5s;
}

.main-menu__right {
  width: 50vw;
  height: 100%;
  background-color: rgba(24, 54, 105, 0.85);
  position: relative;
  right: -100%;
  transition: 1.5s;
}

.main-menu__content {
  position: fixed;
  top: -100%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  height: 50%;
  transition-delay: 1s;

}

.main-menu-list {
  display: flex;
  flex-direction: column;
}

.main-menu-list__item {
  padding: 0;
  margin: 0;
  opacity: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;
}

.main-menu-list__link {
  margin-bottom: 25px;
  text-decoration: none;
  font-size: 55px;
  text-transform: uppercase;
  font-family: Sansus-Webissimo-Regular;
  color: #fff;
  text-decoration: none;
  user-select: none;
  &:after {
    content: '';
    height: 3px;
    display: block;
    width: 20%;
    min-width: 30px;
    margin: auto;
    margin-top: 15px;
    padding: 0 20px;
    user-select: none;
    transition: 2s;
    background-color: #fff;
    opacity: 0;
  }
  &:hover:after {
    opacity: 1;
  }
  @include iphone{
    font-size: 40px;
    word-wrap: normal;
    margin-bottom: 20px;
  }

}

.main-menu.main-menu_active {

z-index: 20;
  .main-menu__content{
    top:50%;
  }
  .main-menu__left {
    left: 0;
  }
  .main-menu__right {
    right: 0;
  }
  .main-menu-list__item {
    opacity: 1;
    transition: 1s;
    transition-delay: 1.5s;
  }
}

.menu-hamburger {
  position: absolute;
  z-index: 30;
  right: 30px;
  top: 28px;
  width: 35px;
  height: 30px;
  cursor: pointer;
  &:hover {
    .menu-hamburger__item, .menu-hamburger__item:before, .menu-hamburger__item:after {
      background: #004cd1;
    }
  }
  @include iphone {
    right: 15px;
    top: 14px;
  }
}
.menu-hamburger__item{width: 100%;height: 100%;user-select: none;}

.menu-hamburger__item span {
  margin: 0 auto;
  position: relative;
  top: 12px;
  border-radius: 10px;
}

.menu-hamburger__item span:before, .menu-hamburger__item span:after {
  position: absolute;
  content: '';
}

.menu-hamburger__item span, .menu-hamburger__item span:before, .menu-hamburger__item span:after {
  width: 35px;
  height: 5px;
  background-color: #fff;
  display: block;
  border-radius: 10px;
  transition: 1s;
}

.menu-hamburger__item span:before {
  transform-origin: 100% 0;
  margin-top: -12px;
}

.menu-hamburger__item span:after {
  margin-top: 12px;
  transform-origin: 100% 100%;
}

.menu-hamburger.menu-hamburger_active {

  .menu-hamburger__item span {
    background-color: rgba(0, 0, 0, 0.0);
    &:before {
      transform: rotate(-45deg);
    }
    &:after {
      transform: rotate(45deg);
    }
  }

}