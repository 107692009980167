.container-signin {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(35, 28, 41, 0.5);
  display: flex;
  transform: rotateY(180deg);
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.login-form {
  width: 100%;
  margin-bottom: -20px;
}

.login-form__wrapper {
  display: flex;
  flex-direction: column;
}

.welcome-box__name {
  margin: 2.25rem 0;
  display: flex;
  align-items: center;
  font: 2.25rem "SansusWebissimo";
  justify-content: center;
}

.text_uppercase {
  text-transform: uppercase;
}

.login-form_field {
  max-width: 340px;
  margin: auto 18px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 3rem;
  margin-bottom: 1.25rem;
}

.login-form__field {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 3rem;
  background: #f0efe9;
}

.login-form__input {
  height: 100%;
  width: 16.25rem;
  border: none;
  padding: 0 1rem;
  font: 1rem "Roboto-Regular";
  color: #373e42;
  outline: none;
}

.login-form__svg {
  width: 1rem;
  height: 1rem;
  fill: #c4cbcd;
}

.log-in__capcha {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2.5rem;
}

.capcha__robot {
  display: flex;
  margin-bottom: 1.25rem;
  justify-content: center;
}

.capcha__chekbox, .answers__radio {
  display: none;
}

.capcha__answer {
  display: flex;
  align-items: center;
  &:before {
    margin-right: 0.3125rem;
    display: inline-block;
    font: 0.875rem "FontAwesome";
    -webkit-transition: all .15s;
    transition: all .15s;

  }
}

.capcha__answer--chekbox {
  &:before {
    content: '';
    height: 1.25rem;
    width: 1.25rem;
    outline: 1px solid #fff;
    cursor: pointer;
    margin-right: 0.625rem;
  }
}

.capcha__answer, .capcha__question {
  font: 1rem "Roboto-Medium";
  color: #fff;
}

.capcha__robot-repeat {
  display: flex;
  flex-direction: column;
}

.capcha__question {
  text-align: center;
  margin-bottom: 0.625rem;
}
.login-form__capcha{
  margin-bottom: 40px;
}
.capcha__robot-repeat {
  display: flex;
  flex-direction: column;
  .capcha__answers {
    display: flex;
    justify-content: center;
  }
  .answers {
    display: flex;
  }
}

.capcha__answer--radio {
  &:before {
    content: '';
    height: 1.25rem;
    width: 1.25rem;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
    margin-right: 0.625rem;
    margin-left: 2.625rem;
  }
}

.capcha__answer--yes {
  &:before {
    margin-left: 0;
  }
}

.buttons-panel__item {
  flex: 1;
  padding: 0.9375rem 0;
  color: #fff;
  text-align: center;
  cursor: pointer;
  border: none;
  outline: none;
}

.buttons-panel {
  display: flex;
  margin-bottom: 0;
  flex-direction: row;
}

.bottom-form--log-in .buttons-panel__item {
  background-color: #0548be;
}

.buttons-panel__item--send[disable=true] {
  color: rgba(255, 255, 255, 0.5);
  background-color: rgba(4, 71, 189, 0);
}

.bottom-form--log-in .buttons-panel__item:last-child {
  border-left: 2px solid #7195d2;
}

.bottom-form--log-in .buttons-panel__item--send[disable=true] {
  color: rgba(255, 255, 255, 0.5);
  background-color: #0548be;
}

.bottom-form--log-in .buttons-panel__item:hover {
  background-color: #083c96;
}

.buttons-panel__item--send:hover {
  background: #0548be;
}

.answers__radio:checked + .capcha__answer--radio:before {
  content: '';
  background-color: #0548be;
  box-shadow: inset 0px 0px 0px 5px #fff;
}

.capcha__chekbox:checked + .capcha__answer--chekbox:before {
  content: '\f00c';
  outline: none;
  background-color: #0548be;
  color: #fff;
  padding-left: 3px;
  line-height: 20px;
  width: 17px;
}