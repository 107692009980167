@font-face {
  font-family: 'Roboto-BoldItalic';
  src: url('../fonts/Roboto-BoldItalic.eot');
  src: url('../fonts/Roboto-BoldItalic.woff2') format('woff2'),
  url('../fonts/Roboto-BoldItalic.woff') format('woff'),
  url('../fonts/Roboto-BoldItalic.ttf') format('truetype'),
  url('../fonts/Roboto-BoldItalic.svg#Roboto-BoldItalic') format('svg'),
  url('../fonts/Roboto-BoldItalic.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Italic';
  src: url('../fonts/Roboto-Italic.eot');
  src: url('../fonts/Roboto-Italic.woff2') format('woff2'),
  url('../fonts/Roboto-Italic.woff') format('woff'),
  url('../fonts/Roboto-Italic.ttf') format('truetype'),
  url('../fonts/Roboto-Italic.svg#Roboto-Italic') format('svg'),
  url('../fonts/Roboto-Italic.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Light';
  src: url('../fonts/Roboto-Light.eot');
  src: url('../fonts/Roboto-Light.woff2') format('woff2'),
  url('../fonts/Roboto-Light.woff') format('woff'),
  url('../fonts/Roboto-Light.ttf') format('truetype'),
  url('../fonts/Roboto-Light.svg#Roboto-Light') format('svg'),
  url('../fonts/Roboto-Light.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-LightItalic';
  src: url('../fonts/Roboto-LightItalic.eot');
  src: url('../fonts/Roboto-LightItalic.woff2') format('woff2'),
  url('../fonts/Roboto-LightItalic.woff') format('woff'),
  url('../fonts/Roboto-LightItalic.ttf') format('truetype'),
  url('../fonts/Roboto-LightItalic.svg#Roboto-LightItalic') format('svg'),
  url('../fonts/Roboto-LightItalic.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('../fonts/Roboto-Medium.eot');
  src: url('../fonts/Roboto-Medium.woff2') format('woff2'),
  url('../fonts/Roboto-Medium.woff') format('woff'),
  url('../fonts/Roboto-Medium.ttf') format('truetype'),
  url('../fonts/Roboto-Medium.svg#Roboto-Medium') format('svg'),
  url('../fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-MediumItalic';
  src: url('../fonts/Roboto-MediumItalic.eot');
  src: url('../fonts/Roboto-MediumItalic.woff2') format('woff2'),
  url('../fonts/Roboto-MediumItalic.woff') format('woff'),
  url('../fonts/Roboto-MediumItalic.ttf') format('truetype'),
  url('../fonts/Roboto-MediumItalic.svg#Roboto-MediumItalic') format('svg'),
  url('../fonts/Roboto-MediumItalic.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('../fonts/Roboto-Regular.eot');
  src: url('../fonts/Roboto-Regular.woff2') format('woff2'),
  url('../fonts/Roboto-Regular.woff') format('woff'),
  url('../fonts/Roboto-Regular.ttf') format('truetype'),
  url('../fonts/Roboto-Regular.svg#Roboto-Regular') format('svg'),
  url('../fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Sansus-Webissimo-Regular';
  src: url('../fonts/Sansus-Webissimo-Regular.eot');
  src: url('../fonts/Sansus-Webissimo-Regular.woff2') format('woff2'),
  url('../fonts/Sansus-Webissimo-Regular.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SansusWebissimo';
  src: url('../fonts/SansusWebissimo.woff') format('woff'),
  url('../fonts/SansusWebissimo.ttf') format('truetype'),
  url('../fonts/SansusWebissimo.svg#SansusWebissimo') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-Bold';
  src: url('../fonts/Roboto-Bold.eot');
  src: url('../fonts/Roboto-Bold.woff2') format('woff2'),
  url('../fonts/Roboto-Bold.woff') format('woff'),
  url('../fonts/Roboto-Bold.ttf') format('truetype'),
  url('../fonts/Roboto-Bold.svg#Roboto-Bold') format('svg'),
  url('../fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}


